import React from 'react';
import { useNavigate } from 'react-router-dom';

import { MoveLeft } from 'lucide-react';

import { useRecoilState } from 'recoil';
import { partnerMeetStateAtom, partnerMeetConfigAtom, meetTypeAtom } from '../core/config/atoms';

import PanelInitMeet from 'panels/customermeet/PanelInitMeet';
import PanelMeet from 'panels/customermeet/PanelMeet';
import PanelThankYou from 'panels/customermeet/PanelThankYou';

import PanelDev from 'panels/dev/PanelDev';
import UpperTimer from 'core/widgets/components/UpperTimer';

export default function LayoutMeetA() {

    const [meetState, setMeetState] = useRecoilState(partnerMeetStateAtom);
    const [meetType, setMeetType] = useRecoilState(meetTypeAtom);

    const [, setMeetConfig] = useRecoilState(partnerMeetConfigAtom);

    const navigate = useNavigate();

    const getTitle = () => {
        switch (meetType) {
            case '1':
                return "Live Face To Face";
            case '2':
                return "Tele - Interpreting";
            case '3':
                return "Helpdesk";
            default:
                return "ISH Call Sign";
        }
    }

    return (
        <div className="w-full h-full py-3 px-3">
            <div className="grid grid-cols-12 grid-gap-2">
                <div className="col-span-12 mt-2">
                    <div className="intro-y flex items-center justify-between h-10">
                        <div className='flex items-center text-2xl space-x-2'>
                            <div className='w-[28px] h-[28px] flex items-center justify-center cursor-pointer' onClick={() => {
                                setMeetType('');
                                setMeetState(0);
                                setMeetConfig({});
                                navigate('/home/main');
                            }}>
                                <MoveLeft size={20} />
                            </div>
                            <h1 className='font-sans font-medium text-gray-900'>{getTitle(meetType)}</h1>
                        </div>
                        <UpperTimer />
                    </div>
                    <hr className='mt-2' />
                </div>
                <div className="col-span-12 mt-2 h-screen bg-gray-100">
                    <div className='w-full min-h-[84vh] md:min-h-[74vh] px-[2%] md:px-[10%] flex items-center justify-center'>
                        {
                            (() => {
                                switch (meetState) {
                                    case 0:
                                        return (<PanelInitMeet />);
                                    case 1:
                                        return (<PanelMeet />);
                                    case 2:
                                        return (<PanelThankYou />);
                                    default:
                                        return (<PanelDev />);
                                }
                            })()
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
