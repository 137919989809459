import React from 'react';
import { useParams } from 'react-router-dom';

import NavigationBar from '../core/widgets/ui/NavigationBar';

import LayoutHome from '../layouts/LayoutHome';
import LayoutTicket from '../layouts/LayoutTicket';
import LayoutSetting from '../layouts/LayoutSetting';

export default function PageHome() {

    const { page } = useParams();

    return (
        <div className='w-full h-screen overflow-hidden flex'>
            <NavigationBar />
            {(() => {
                switch (page) {
                    case 'main':
                        return (<LayoutHome />);
                    case 'ticket':
                        return (<LayoutTicket />);
                    case 'setting':
                    case 'subscription':
                    case 'feedback':
                    case 'terms':
                    case 'policy':
                        return (<LayoutSetting />);
                    default:
                        return (
                            <div className="w-full h-full text-5xl flex items-center justify-center" style={{ height: "80vh" }}>
                                No Data Found
                            </div>
                        );
                }
            })()}
        </div>
    )
}
