import React, { useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { walletAtom } from '../../../core/config/atoms';

import { Clock } from 'lucide-react';

export default function WalletTimer(param) {

    const [wallet] = useRecoilState(walletAtom);

    const formatMinutesToTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const remainingSeconds = seconds % 3600;
        const minutes = Math.floor(remainingSeconds / 60);
        const finalSeconds = remainingSeconds % 60;
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedFinalSeconds = String(finalSeconds).padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}:${formattedFinalSeconds}`;
    }

    const getBgColor = () => {
        if (wallet.balence < 180) {
            return "bg-red-600";
        }
        else {
            return "bg-prime";
        }
    }

    useEffect(() => {
        if (wallet.balence < 0) {
            param.end();
        }
    }, [wallet]);

    return (
        <div className={`h-10 text-sm text-white ${getBgColor()} mx-2 px-4 rounded-2xl flex items-center justify-center cursor-pointer space-x-2`}>
            <Clock size={16} /><span>{formatMinutesToTime(wallet.balence)} Min</span>
        </div>
    )
}
