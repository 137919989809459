import React from 'react';

import { useRecoilState } from 'recoil';
import { walletAtom } from '../../../core/config/atoms';

export default function UpperTimer() {

    const [wallet, setWallet] = useRecoilState(walletAtom);

    const formatMinutesToTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const remainingSeconds = seconds % 3600;
        const minutes = Math.floor(remainingSeconds / 60);
        const finalSeconds = remainingSeconds % 60;
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedFinalSeconds = String(finalSeconds).padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}`;
    }

    const getBgColor = () => {
        if (wallet.balence < 180) {
            return "bg-red-600";
        }
        else {
            return "bg-prime";
        }
    }

    return (
        <div className={`text-xs lg:text-sm text-white ${getBgColor()} px-2 lg:px-3 py-1 rounded-full flex cursor-pointer`}><span className='hidden lg:block'>Balance:&nbsp;</span>{formatMinutesToTime(wallet.balence)} Min</div>
    )
}
