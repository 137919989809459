import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ScrollArea } from "@/components/ui/scroll-area";

import { useRecoilState } from 'recoil';
import { userAtom, meetTypeAtom, partnerMeetConfigAtom, partnerMeetStateAtom } from '../../core/config/atoms';

import TopBar from '../../core/widgets/ui/TopBar';
import socket from '../../core/config/socket';

export default function PanelMain() {

    const navigate = useNavigate();

    const [userData] = useRecoilState(userAtom);

    const [, setMeetType] = useRecoilState(meetTypeAtom);
    const [, setMeetConfig] = useRecoilState(partnerMeetConfigAtom);
    const [, setMeetState] = useRecoilState(partnerMeetStateAtom);

    const gotoMeet = (type) => {
        setMeetType(type);
        navigate('/meet');
    }

    useEffect(() => {
        setMeetType('');
        setMeetState(0);
        setMeetConfig({});
    }, []);

    return (
        <div className='w-full h-screen overflow-hidden flex'>
            <div className="w-full h-full py-3 px-3">
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div className="intro-y flex items-center justify-between h-10">
                            <h2 className="text-2xl font-medium truncate ml-2 text-gray-900">
                                {userData.user.fname}{userData.user.lname === "NA" ? "" : ` ${userData.user.lname}`}
                            </h2>
                            <TopBar />
                        </div>
                        <hr className='mt-2' />
                    </div>
                    <div className="col-span-12 mt-2 h-screen">
                        <ScrollArea className='h-screen w-full'>
                            <div className='w-full h-full px-2'>
                                <div className='h-[90%] flex justify-center'>
                                    <div className='w-[90%] lg:w-[60%] h-[80%] grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8'>
                                        <div className='col-span-1 border rounded hover:shadow p-2 space-y-2'>
                                            <div>
                                                <img src='https://storage.googleapis.com/ish-prod/ish-assets/banner.jpg' alt='menu-banner' className='w-full h-full object-contain rounded' />
                                            </div>
                                            <div className='space-y-2'>
                                                <p className='text-sm font-poppins font-medium text-center'>Live Face To Face Interpreting</p>
                                                <button className='w-full bg-prime text-white h-[36px] rounded-md' onClick={() => { gotoMeet('1') }}>Call</button>
                                            </div>
                                        </div>
                                        <div className='col-span-1 border rounded hover:shadow p-2 space-y-2'>
                                            <div>
                                                <img src='https://storage.googleapis.com/ish-prod/ish-assets/banner.jpg' alt='menu-banner' className='w-full h-full object-contain rounded' />
                                            </div>
                                            <div className='space-y-2'>
                                                <p className='text-sm font-poppins font-medium text-center'>Tele - Interpreting</p>
                                                <button className='w-full bg-prime text-white h-[36px] rounded-md' onClick={() => { gotoMeet('2') }}>Call</button>
                                            </div>
                                        </div>
                                        <div className='col-span-1 border rounded hover:shadow p-2 space-y-2'>
                                            <div>
                                                <img src='https://storage.googleapis.com/ish-prod/ish-assets/banner.jpg' alt='menu-banner' className='w-full h-full object-contain rounded' />
                                            </div>
                                            <div className='space-y-2'>
                                                <p className='text-sm font-poppins font-medium text-center'>Helpdesk</p>
                                                <button className='w-full bg-prime text-white h-[36px] rounded-md' onClick={() => { gotoMeet('3') }}>Call</button>
                                            </div>
                                        </div>
                                        <div className='col-span-1 border rounded hover:shadow p-2 space-y-2'>
                                            <div>
                                                <img src='https://storage.googleapis.com/ish-prod/ish-assets/banner.jpg' alt='menu-banner' className='w-full h-full object-contain rounded' />
                                            </div>
                                            <div className='space-y-2'>
                                                <p className='text-sm font-poppins font-medium text-center'>External Services</p>
                                                <button className='w-full bg-prime text-white h-[36px] rounded-md' onClick={() => { gotoMeet('4') }}>Call</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='h-[180px] lg:hidden'></div>
                            </div>
                        </ScrollArea>
                    </div>
                </div>
            </div>
        </div>
    )
}
